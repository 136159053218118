$(document).ready(function () {
  let $modal_qr = $('.js-modal--reg-check'), $qr_container = $modal_qr.find('.modal__qr');
  if ($modal_qr.length < 1) return;

  let video = document.getElementById('preview'),
    canvasElement = document.createElement('canvas'),
    canvas = canvasElement.getContext('2d');

  console.log(typeof navigator.mediaDevices === 'undefined');

  if (typeof navigator.mediaDevices === 'undefined') {
    // для chrome@android будет загрузка файла
    $('.js--check_type_qr').addClass('invisible');
    $('.js--check_type_file').removeClass('invisible');

    $('.js--check_image_attach').on('change', function (event) {
      let _type = null, _bg = event.target.files[0];

      $('.js--check_type_qr').addClass('invisible');
      $('.js--check_type_file').removeClass('invisible');
      $('#check_qr_qr').val('');

      if (typeof _bg !== 'undefined' && _bg !== null) {
        _type = _bg.type;

        if (_type.startsWith('image/')) {
          QrScanner.scanImage(_bg)
            .then(function (result) {
              if (result.length > 10) {
                // console.log(result);
                $('#check_qr_qr').val(result);

                // $qr_container.addClass('modal__qr--scanned');
                // $qr_container.find('.qr__container').empty().qrcode(result);

                $('.js--qr__errors').hide();
                $('.js--check_type_file').removeClass('form__qr--error').addClass('form__qr--success');
                // $('#check_qr_qr').closest('form').trigger('submit');
              }
            })
            .catch(function (e) {
              // console.log(e || 'No QR code found.');
              $('.js--check_type_file').removeClass('form__qr--success').addClass('form__qr--error');
              $('.js--qr__errors').html('QR-код не найден,<br>выберите другой файл').show();
            });
        }
      }
    });
    return false;
  } else {
    // jsQR
    (function () {
      $('[data-bs-target="#modal-receipt_qr"]').on('click', function () {
        startVideo();
      });

      function startVideo() {
        if (video.srcObject === null) {
          navigator.mediaDevices.getUserMedia({video: {facingMode: "environment", frameRate: {ideal: 5, max: 10}}})
            .then(function (stream) {
              video.srcObject = stream;
              video.setAttribute('autoplay', true);
              video.setAttribute('playsinline', true); // required to tell iOS safari we don't want fullscreen
              video.play();
            });
        }
      }

      function stopVideo() {
        video.srcObject.getTracks().forEach(function (track) {
          track.stop();
        });
        video.srcObject = null;
      }

      raf(function checkVideoIsVisible() {
        if ($modal_qr.is(':visible')) {
          if (video.srcObject !== null) {
            try {
              canvasElement.height = video.videoHeight;
              canvasElement.width = video.videoWidth;
              canvas.drawImage(video, 0, 0, canvasElement.width, canvasElement.height);
              let imageData = canvas.getImageData(0, 0, canvasElement.width, canvasElement.height),
                code = jsQR(imageData.data, imageData.width, imageData.height, {
                  inversionAttempts: "dontInvert",
                });

              if (code) {
                stopVideo();

                // console.log(code.data);
                $('#check_qr_qr').val(code.data);

                $qr_container.addClass('modal__qr--scanned');
                $qr_container.find('.qr__container').empty().qrcode(code.data);

                // $('#check_qr_qr').closest('form').trigger('submit');
              }
            } catch (e) {
              //console.log('PROBLEM: ', e);
            }
          }
        } else {
          $qr_container.removeClass('modal__qr--scanned');
          $('#check_qr_qr').val('');
          if (video.srcObject !== null) {
            stopVideo();
          }
        }
        raf(checkVideoIsVisible);
      });
    })();
  }
});
